@import url(https://fonts.googleapis.com/css?family=Roboto:300,400);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.4.0/css/font-awesome.min.css);

.sidebar_header {
  background-image: url('../../assets/images/backgroung.png');
color: white;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 768px) {
  .InfoSection_img img{
      justify-content: center !important;
        align-items: center;
        align-self: center;
        border-radius: 50%;
    }
.InfoSection_img {
  flex-direction: column;

}
}
