.SectionOne{
background-image: url(../../assets/images/r.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 3em 4em 2em;
    min-height: 100vh;
    position: relative;
}
.SectionFour{
    background-image: url(../../assets/images/wave1.png);
        background-size: cover;
        background-position: center;
        padding: 4em 2em;
}
.SectionThreePallarax{
background-image: url(../../assets/images/r3.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 4em 2em;
    position: relative;
}